html,
body {
  padding: 0;
  margin: 0;
  transition: background-color 0.3s ease-in-out;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

* {
  box-sizing: border-box;
}

.react-datepicker__day {
  border-radius: 3px !important;
}

.react-datepicker__day--in-range,
.react-datepicker__day--selected {
  background-color: #2866ff !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__day--selected) {
  background-color: #e9efff !important;
  color: #333333;
}

.additionalFields .os-s-array {
  padding: 0;
  background: none;
  border: none;
  cursor: auto;
}

.additionalFields .os-s-array > .os-array-item-content > div {
  padding: 0 !important;
}